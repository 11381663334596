import styledBreakpoint from "@humblebee/styled-components-breakpoint";

export const FONT = {
  serif: '"Inknut Antiqua", serif',
  sansSerif: '"Noto Sans", sans-serif',
};

export const COLOR = {
  gray: "#757575",
  black: "#000",
  white: "#fff",
};

export const BREAKPOINT = styledBreakpoint({
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});
