/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
// import Nav from "@src/components/Nav";
import Footer from "@src/components/Footer";
import "./layout.css";
import { BREAKPOINT } from "../global-theme";

const Content = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 95vh;
  padding: 0.0875rem 1.45rem;

  a {
    text-decoration: none;
    color: black;
  }

  img {
    max-width: 100%;
  }

  ${BREAKPOINT.md`
    padding: 3.0875rem 2.45rem;
  `}
`;

interface LayoutTypes {
  children;
}
const Layout = ({ children }: LayoutTypes) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <BodyContainer>
        {/* <Nav /> */}
        <Content>{children}</Content>
        <Footer />
      </BodyContainer>
    </>
  );
};

export default Layout;
