import React from "react";
// import { Link } from "@reach/router";
import styled from "styled-components";
import { COLOR } from "@src/global-theme";
import {
  Email,
  Github,
  Twitter,
  Linkedin,
} from "@components/icons/SocialIcons";

const SVG_WIDTH = 20;

const Footer = styled.footer`
  padding: 3rem 0 0 0;
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.6;
  }
  svg {
    margin: 0.5rem 0.5rem 0 0.5rem;
  }
`;

const Copyright = styled.p`
  /* text-transform: uppercase; */
  letter-spacing: 0.5px;
  margin: 1.45rem 0;
  color: ${COLOR.gray};
`;

export default () => {
  const year = new Date().getFullYear();

  return (
    <Footer>
      <FooterWrap>
        <Link
          href="mailto:kayla.robertson3@gmail.com"
          target="_blank"
          aria-label="Email"
        >
          <Email width={SVG_WIDTH} />
        </Link>
        <Link
          href="https://github.com/kaylarobertson3"
          target="_blank"
          aria-label="Github"
        >
          <Github width={SVG_WIDTH} />
        </Link>
        <Link
          href="https://www.linkedin.com/in/kaylarobertson/"
          target="_blank"
          aria-label="Linkedin"
        >
          <Linkedin width={SVG_WIDTH} />
        </Link>
        <Link
          href="https://twitter.com/kaylajoro"
          target="_blank"
          aria-label="Twitter"
        >
          <Twitter width={SVG_WIDTH} />
        </Link>
      </FooterWrap>
      <Copyright style={{ fontSize: 12 }}>
        &copy; Kayla Robertson {year}
      </Copyright>
    </Footer>
  );
};
